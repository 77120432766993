import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import useUser from '@hooks/useUser';
import { SplashScreen, HomeTopTopics, HomePosts } from '@components';
import { App } from '@layouts';
import useABTesting from 'hooks/useABTesting';

const Index = () => {
  const { t } = useABTesting({}, 'pages.feed');
  const { loading, session } = useUser();

  if (loading) {
    return <SplashScreen />;
  }

  useEffect(() => {
    if (!session) {
      navigate('/');
    }
  }, []);

  return (
    <App title={t('title')}>
      <HomeTopTopics />
      <HomePosts />
    </App>
  );
};

export default Index;
